<template>
  <div id="interior-container">
    <Head />
    <van-dropdown-menu class="menu">
      <van-dropdown-item
        @change="changeValue"
        v-model="value"
        :options="option"
      />
    </van-dropdown-menu>
    <van-list
      class="list van-clearfix"
      v-model:loading="loading"
      :finished="finished"
      :immediate-check="false"
      loading-text="Loading"
      finished-text="No More"
      @load="onLoad"
    >
      <div class="list-div van-clearfix">
        <div class="item" v-for="item in list" :key="item" @click="goArticle(item.article_id, 3)">
          <van-image
            fit="cover"
            width="90%"
            height="15rem"
            :src="imgUrl + item.pic"
          />
          <p>{{ item.title }}</p>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Head from '@/components/head';
export default {
  data() {
    return {
      imgUrl: "http://img.kar-studio.com",
      active: "",
      activeSub: "",
      column: [],
      option: [],
      option1: [],
      value: 0,
      category_id: 0,
      loading: false,
      finished: false,
      list: [],
      page: 1,
      pageSize: 8,
      type: 0,
      name: "",
    };
  },
  components: {
    Head
  },
  computed: {},
  methods: {
    goArticle(id, type) {
      this.$router.push({path: "/article", query: {id, type}})
    },
    changeValue(value) {
      this.page = 1;
      this.list = [];
      this.loading = false;
      this.loading = false;
      if (value != 0) {
        this.type = this.option1[value].type;
        this.category_id = this.option1[value].id;
        this.onLoad();
      } else {
        this.getColumnLink();
      }
    },
    onLoad() {
      this.loading = true;
      this.$api
        .goodsList({
          type: this.type,
          category_id: 0,
          column_id: this.category_id,
          name: this.name,
          page: this.page,
        })
        .then((res) => {
          if (res.data.status) {
            let data = res.data.data.data;
            this.list = this.list.concat(data);
            console.log(this.list);
            this.loading = false;
            this.page++;
            if (data.length < this.pageSize) {
              this.finished = true;
            }
          }
        });
    },
    getColumnLink() {
      this.$api.columnLink().then((res) => {
        if (res.data.status) {
          this.column = res.data.data;
          let arr = [];
          let arr1 = [];
          this.column.forEach((e) => {
            if (e.name == this.active) {
              e.children.forEach((e1, k1) => {
                arr.push({
                  text: e1.name,
                  value: e1.id,
                  icon: "",
                });
                if (k1 == 0) {
                  this.type = e1.type;
                  this.category_id = e1.id;
                }
                arr1[e1.category_id] = e1;
              });
            }
          });
          arr.unshift({
            text: this.active,
            value: 0,
            icon: "",
          });
          console.log(arr);
          console.log(arr1);
          this.option = arr;
          this.option1 = arr1;
          console.log(arr1);
          this.onLoad();
        }
      });
    },
  },
  created() {
    this.getColumnLink();
  },
  mounted() {
    let route = this.$route;
    this.active = route.matched[0].name;
    if (route.matched.length > 1) {
      this.activeSub = route.matched[1].name;
    }
  },
};
</script>

<style lang="scss" scoped>
#interior-container {
  .menu {
    margin-top: 140px;
    height: 120px;
  }
  .list {
    width: 100%;
    height: calc(100vh - 140px - 120px);
    overflow-x: hidden;
    .list-div {
      width: 100%;
      .item {
        width: 50%;
        float: left;
        margin-top: 20px;
        img {
          display: block;
          margin: 0 auto;
        }
        p {
          width: 90%;
          font-size: 20px;
          margin: 0 auto;
          display: block;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
